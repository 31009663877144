.block-side-menu-wrapper {
    .p-tabview .p-tabview-panels {
        padding: 0;
    }

    .p-tabview .p-tabview-nav {
        background: var(--surface-50);
        border-width: 1px;

        li {
            cursor: pointer;
            min-width: 100px;
            border-bottom: 2px solid transparent;
        }

        li.p-tabview-selected {
            border-bottom: 2px solid var(--ion-color-primary) !important;
        }

        li:hover {
            background: var(--surface-100);
            border-bottom: 2px solid var(--bluegray-200);
        }
    }
}

//
