// Typography

// Add property horizontal
// @param {string} $start
// @param {string} $end
// ----------------------------------------------------------
@mixin property-horizontal($prop, $start, $end: $start) {
    @if $start == 0 and $end == 0 {
        #{$prop}-left: $start;
        #{$prop}-right: $end;
    } @else {
        #{$prop}-left: $start;
        #{$prop}-right: $end;

        @at-root {
            @supports ((margin-inline-start: 0) or (-webkit-margin-start: 0)) {
                & {
                    -webkit-#{$prop}-start: $start;
                    #{$prop}-inline-start: $start;
                    -webkit-#{$prop}-end: $end;
                    #{$prop}-inline-end: $end;

                    @if $start != null {
                        #{$prop}-left: unset;
                    }
                    @if $end != null {
                        #{$prop}-right: unset;
                    }
                }
            }
        }
    }
}

// Add padding horizontal
// @param {string} $start
// @param {string} $end
// ----------------------------------------------------------
@mixin padding-horizontal($start, $end: $start) {
    @include property-horizontal(padding, $start, $end);
}

// Add property for all directions
// @param {string} $prop
// @param {string} $top
// @param {string} $end
// @param {string} $bottom
// @param {string} $start
// @param {boolean} $content include content or use default
// ----------------------------------------------------------
@mixin property($prop, $top, $end: $top, $bottom: $top, $start: $end) {
    #{$prop}-top: $top;
    #{$prop}-bottom: $bottom;
    @include property-horizontal($prop, $start, $end);
}

// --------------------------------------------------
// Add margin for all directions
// @param {string} $top
// @param {string} $end
// @param {string} $bottom
// @param {string} $start
// ----------------------------------------------------------
@mixin margin($top, $end: $top, $bottom: $top, $start: $end) {
    @include property(margin, $top, $end, $bottom, $start);
}

/// @prop - Font weight of all headings
$headings-font-weight: 500 !default;

/// @prop - Line height of all headings
$headings-line-height: 1.2 !default;

/// @prop - Font size of heading level 1
$h1-font-size: 1.6rem !default;

/// @prop - Font size of heading level 2
$h2-font-size: 1.4rem !default;

/// @prop - Font size of heading level 3
$h3-font-size: 1.17rem !default;

/// @prop - Font size of heading level 4
$h4-font-size: 1.1rem !default;

/// @prop - Font size of heading level 5
$h5-font-size: 1rem !default;

/// @prop - Font size of heading level 6
$h6-font-size: 1rem !default;

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    @include margin(16px, null, 10px, null);
}

h1 {
    font-size: $h1-font-size;
    @include margin(20px, null, null, null);
}

h2 {
    font-size: $h2-font-size;
    @include margin(18px, null, null, null);
}

h3 {
    font-size: $h3-font-size;
}

h4 {
    font-size: $h4-font-size;
}

h5 {
    font-size: $h5-font-size;
}

h6 {
    font-size: $h6-font-size;
}

p {
    @include margin(null, null, 1rem, null);
}

small {
    font-size: 75%;
}

sub,
sup {
    position: relative;

    font-size: 75%;

    line-height: 0;

    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}
