.add-page-indicator-wrapper {
    cursor: pointer;
    height: 10px;
    opacity: 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    position: relative;

    .add-page-indicator {
        height: 4px;
        width: 100%;
        background-color: var(--primary-200);
    }

    .add-page-indicator-icon {
        color: white;
        background-color: var(--primary-200);
        position: absolute;
        right: -20px;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.add-page-indicator-wrapper:hover {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.add-page-indicator-wrapper:active {
    .add-page-indicator,
    .add-page-indicator-icon {
        background-color: var(--primary-400);
    }
}
