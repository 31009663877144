.small-menu-btn {
    padding: 0.2rem;
    margin: 0.5rem 0;
    width: 3.8rem;
    height: 4rem;

    .small-btn-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        .small-btn-text {
            font-size: 0.7rem;
            line-height: 0.8rem;
            margin-top: 0.2rem;
        }
    }
}
