.drag-handle-wrapper {
    opacity: 0;
    position: relative;
    width: 100%;
    color: var(--gray-700);

    .drag-handle {
        width: 25px;
        height: 4rem;
        padding-left: 3px;

        position: absolute;
        top: -5px;
        right: -29px;
        z-index: 1;

        .drag-handle-btn {
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .drag-handle-btn:hover {
            background-color: var(--surface-hover);
        }

        .drag-handle-btn:active {
            cursor: grabbing;
        }
    }
}

.grabbable-button:hover .drag-handle-wrapper,
.grabbable-button:focus .drag-handle-wrapper,
.grabbable-button:active .drag-handle-wrapper {
    opacity: 1;
}
