.loader-widget {
    background: var(--surface-a);
    border-radius: var(--border-radius);

    min-width: 80px;
    max-width: 160px;
    height: 80px;

    .spinner {
        animation: spin 2s infinite linear;
    }
}
