@import "./styles/index.css";

// A4 210 x 297
$editor-width: calc(210px * 4);
$editor-height: calc(297px * 4);

.tippy-box {
    max-width: fit-content !important;
}

.tiptap {
    font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.mini-tiptap-wrapper {
    border-radius: 0.5rem;
    border: 1px solid var(--surface-border);

    .ProseMirror {
        max-width: 52rem;
    }
}

.tiptap-wrapper .tiptap {
    border-radius: 0.5rem;
    box-shadow: var(--dms-box-shadow);
}

.tiptap-wrapper .tiptap.ProseMirror {
    min-height: calc($editor-height * var(--editor-scale));
    width: calc($editor-width * var(--editor-scale));
    max-width: 100vw;
    background: var(--surface-a);
    padding: calc(50px * var(--editor-scale)) 80px;
}

.ProseMirror [data-type="columns"],
.ProseMirror > .react-renderer {
    margin: 5px 0;
}

.ProseMirror [data-type="column"] {
    overflow: auto;
}

.ProseMirror [data-type="columns"],
.ProseMirror [data-type="column"],
.node-pageBreak,
.node-spacerBlock,
.node-imageBlock,
.node-horizontalLine,
.node-signature {
    border-radius: 0.5rem;
    border-width: 2px;
    border-style: dotted;
    border-color: transparent;
    transition: border 160ms cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.ProseMirror [data-type="columns"]:hover,
.ProseMirror [data-type="column"]:hover,
.node-pageBreak:hover,
.node-spacerBlock:hover,
.node-imageBlock:hover,
.node-horizontalLine:hover,
.node-signature:hover {
    --tw-border-opacity: 1;
    border-color: var(--primary-100);
}

.ProseMirror [data-type="column"].has-focus,
.node-pageBreak.has-focus,
.node-spacerBlock.has-focus,
.node-imageBlock.has-focus,
.node-horizontalLine.has-focus,
.node-signature.has-focus {
    border: 2px dotted var(--primary-200) !important;
}

.ProseMirror {
    h1,
    h2,
    h3,
    p {
        margin: 0;
        color: black;
    }
    h1.report-heading {
        font-size: calc(2.6rem * var(--editor-scale));
        font-weight: normal;
    }

    h2.report-heading {
        font-size: calc(1.6rem * var(--editor-scale));
        font-weight: normal;
    }

    h3.report-heading {
        font-size: calc(1.3rem * var(--editor-scale));
        font-weight: normal;
    }

    p.report-paragraph,
    .signature-text {
        font-size: calc(1.01rem * var(--editor-scale));
    }

    ol,
    ul {
        margin: 0;
    }
}

.tiptap-wrapper {
    width: calc(100vw - var(--sidebar-width));
}

.block-side-menu-wrapper {
    background: var(--surface-a);
    border-left: 1px solid var(--surface-border);
    box-shadow: var(--dms-box-shadow);
    z-index: 1;
}
