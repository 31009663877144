.node-pageBreak:hover {
    cursor: pointer;
}

.block-page-break {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0;
}

.block-page-break .page-break-line {
    height: 1px;
    border-bottom: 1px dashed var(--surface-500);
}

.block-page-break .page-break-text {
    color: var(--surface-600);
}