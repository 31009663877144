.minimise-menu-btn {
    top: 29px;
    left: 223px;
    position: absolute;
    width: 50px;
    height: 50px;
    z-index: 1000;

    .p-button {
        border-radius: 0 0.5rem 0.5rem 0;
    }
}