.grabbable-button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0.5rem 0.2rem;
    width: 3.8rem;
    width: 100%;

    background: var(--surface-a);
    border: 2px solid var(--surface-border);
    border-radius: 6px;

    .grabbable-label {
        text-align: center;
        font-size: 0.7rem;
        line-height: 0.8rem;
        margin-top: 0.2rem;
    }

    .grabbable-delete-btn {
        opacity: 0;
        position: absolute;
        top: -6px;
        padding: 0.25rem;

        svg {
            height: 16px;
            width: 16px;
        }
    }
}

.grabbable-button:hover {
    border: 2px solid var(--primary-200);
    background: var(--surface-b);

    .grabbable-delete-btn {
        opacity: 0.8;
    }

    .grabbable-delete-btn:hover {
        opacity: 1;
    }
}

.page-element-selected {
    border: 2px solid var(--primary-400);
}
