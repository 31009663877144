.image-picker-wrapper {
    background: var(--surface-100);
    border-radius: var(--border-radius);
    height: 260px;

    .img-wrapper {
        position: relative;
        height: 260px;

        img {
            max-height: 96%;
            width: auto;
            max-width: 96%;
            margin: auto;
        }

        .image-delete-btn {
            position: absolute;
            top: 10px;
            right: 10px;
            opacity: 0;
            pointer-events: none;

            .p-button.p-button-sm {
                padding: 0.5rem;
            }
        }
    }

    .img-wrapper:hover {
        .image-delete-btn {
            transition: opacity 0.2s ease-in-out;
            opacity: 1;
            pointer-events: all;
        }
    }

    .p-fileupload {
        height: 260px;

        .p-fileupload-buttonbar {
            padding: 0.5rem;
        }

        .p-fileupload-content {
            height: 207px;
        }
    }

    .custom-choose-btn {
        padding: 0.5rem 1rem;
        color: var(--surface-800);
        background: var(--surface-200);
        border: 1px solid var(--surface-border);
    }

    .custom-choose-btn:hover,
    .custom-choose-btn:active {
        // padding: 0.5rem 1rem;
        color: var(--surface-900);
        background: var(--surface-300);
        border: 1px solid var(--surface-border);
    }
}
