// A4 210 x 297
$editor-width: calc(15rem * 4);
$editor-height: calc(21rem * 4);

// do a media query with min width 1024
.layout-wrapper {
    width: calc(100vw - var(--sidebar-width));
}

.large-preview {
    box-shadow: var(--dms-box-shadow);
    background: var(--surface-a);
}

.large-menu {
    width: 300px;
    overflow-y: auto;
    border-left: 1px solid var(--surface-border);
    box-shadow: var(--dms-box-shadow);
}

.large-preview {
    min-height: calc($editor-height * 0.8 * var(--editor-scale));
    max-width: calc($editor-width * var(--editor-scale));
    padding: calc(2rem * var(--editor-scale)) calc(4rem * var(--editor-scale));
    border-radius: 0.5rem;
}

.a4page {
    overflow: hidden;
    cursor: pointer;
    pointer-events: none;
}

.dfl-header {
    margin-bottom: 3px;
}

.dfl-header-skeleton {
    height: 1rem;
    margin-bottom: 3px;
    background: var(--surface-200);
    border-radius: 4px;
}
.dfl-header .dfl-header-skeleton:first-child {
    width: 180px;
}

.dfl-header .dfl-header-skeleton:last-child {
    width: 120px;
}

.placeholder-svg {
    display: block;
    width: 100%;
    height: auto;
}
