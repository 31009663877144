.block-signature-line {
    .signature-text {
        border-bottom: 2px solid transparent;
    }

    .signature-pad {
        border-bottom: 2px dotted black;
        margin: 0 10px 6px 5px;
    }

    .signature-date {
        border-bottom: 2px dotted black;
        width: 40px;
        margin: 0 5px 6px 5px;
    }
}