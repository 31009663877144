.list-header {
    width: 100%;
    padding-bottom: 5%;
    background: var(--surface-200);
    border-radius: 4px;
}

.dfl-wrapper {
    padding-top: 0.5rem;
    border-bottom: 2px solid var(--ion-color-dark);
    padding-bottom: 0.5rem;
    width: 100%;
    min-height: 40px;
}

.dfl-photos {
    width: 100%;
    margin-top: 0.5rem;

    .dfl-photo {
        width: 25%;
        margin-right: 10%;
    }
}

.dfl-photo,
.dfl-inline-photo {
    .dfl-svg {
        background: var(--ion-color-light-shade);
        border-radius: 4px;
        max-width: 100%;
        height: auto;
    }

    .dfl-skeleton {
        height: 1.2rem;
        margin-bottom: 4px;
        background: var(--ion-color-light-shade);
        border-radius: 4px;
    }
}

.dfl-block {
    margin-top: 0.5rem;

    .dfl-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 2px 5px;

        p {
            width: 7rem;
            font-weight: bold;
            color: var(--ion-color-dark);
            margin: 0px;
        }

        .dfl-skeleton {
            height: 1.4rem;
            width: 50%;
            background: var(--ion-color-light-shade);
            border-radius: 4px;
        }
    }
}

.dfl-inline-photo {
    width: 25%;
    height: auto;
}
