.ProseMirror {
  @apply caret-black dark:caret-white outline-0 pr-8 pl-20 py-16 z-0 lg:pl-8 lg:pr-8 mx-auto max-w-2xl;

  .selection {
    @apply inline-block;
  }

  .selection,
  *::selection {
    @apply bg-black/10 dark:bg-white/20 inline-block;
  }

  & > .react-renderer {
    @apply my-12 first:mt-0 last:mb-0;
  }

  &.resize-cursor {
    @apply cursor-col-resize;
  }

  .ProseMirror-gapcursor {
    @apply relative w-full max-w-2xl mx-auto;

    &:after {
      @apply border-t-black/40 dark:border-t-white/40 w-full -top-[1.5em] max-w-2xl mx-auto left-0 right-0;
    }
  }
}

[data-theme="slash-command"] {
  width: 1000vw;
}

@import "./partials/animations.css";
@import "./partials/blocks.css";
@import "./partials/code.css";
@import "./partials/collab.css";
@import "./partials/lists.css";
@import "./partials/placeholder.css";
@import "./partials/table.css";
@import "./partials/typography.css";
@import "./blocks/signature.css";
@import "./blocks/horizontal-line.css";
@import "./blocks/page-break.css";
@import "./blocks/image-block.css";
