.ProseMirror {
  /* Placeholder Styles */
  .is-empty::before {
    @apply text-black/40 dark:text-white/40 float-left h-0 pointer-events-none w-full;
  }

  &.ProseMirror-focused {
    /* Slashmenu Placeholder */
    > p.has-focus.is-empty::before {
      content: 'Type  /  to browse options';
    }

    > [data-type='columns'] > [data-type='column'] > p.is-empty.has-focus::before {
      content: 'Type  /  to browse options';
    }
  }

  /* Default Placeholder */
  & > :not([data-type='columns']).is-editor-empty::before {
    content: 'Click here to start writing …';
  }

  [data-type='columns'] > [data-type='column'].is-empty:not(.has-focus)::before {
    content: 'Click here to start writing …';
  }

  [data-placeholder][data-suggestion]::before,
  [data-placeholder][data-suggestion] *::before {
    content: none !important;
  }

  /* Details Placeholder */
  [data-type='details'].is-editor-empty::before {
    content: none !important;
  }

  [data-type='details'] summary.is-empty::before {
    content: 'Enter a summary';
  }

  [data-type='detailsContent'] .is-empty::before {
    content: 'Enter details';
  }
}
