@tailwind base;
@tailwind components;
@tailwind utilities;

@import "primereact/resources/themes/lara-light-cyan/theme.css";
@import "./theme/typography.scss";
@import "./theme/variables.scss";
@import "./theme/test-theme.css";
@import "remixicon/fonts/remixicon.css";

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

html {
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ion-color-primary);
  font-weight: bold;
}

body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

.surface-ground {
  background-color: var(--surface-ground);
}

.surface-section {
  background-color: var(--surface-50);
}

.hide-this {
  display: none;
}

.breadcrumb-active-element {
  font-weight: 500;
  pointer-events: none;
}

// Override for buttons wrapped in a Tooltip which adds a span
.p-button-group span:first-of-type {
  .p-button {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.p-button-group span:last-of-type {
  .p-button {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}

.classy-scrollbar::-webkit-scrollbar {
  width: 10px;
  max-height: 10px;
}

.classy-scrollbar::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: #e7e7e7;
  border: 1px solid var(--surface-border);
}

.classy-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: var(--bluegray-200);
}